import React, { useEffect, useRef, useState } from 'react';
import {
	Typography,
	IconButton,
	Zoom,
	Dialog,
	DialogTitle,
	Button,
	DialogActions,
	DialogContent,
	CircularProgress,
	Autocomplete,
	TextField,
} from '@mui/material';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
	AddCircle,
	CloseRounded,
	Edit,
	EditRounded,
	Link,
} from '@mui/icons-material/';
import { usePO } from '../../../utils/POContext';
import {
	OfferToolTip,
	TextMaskCEP,
	TextMaskDynamicRegNumber,
	TextMaskPhone,
	statesUf,
} from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../styles';
import FormDivider from '../../../components/FormDivider';
import TagSelector from '../../../components/TagSelector';
import AppraisalModal from '../AppraisalModal';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import cep from 'cep-promise';
import { allCountries } from '../../../helpers';
import { useSnackbar } from 'notistack';
import MultiFileCard from '../../../components/MultiFileCard';
import {
	createNewEntity,
	getAppraisals,
	getAuthorsOnEntity,
	removeEntitiesFiles,
	updateEntity,
} from '../../../services/entities';
import OpenAuthorCard from '../OpenAuthorCard';
import OfferTable from '../../../components/OfferTable';
import { EntityRowData } from '..';
import { CountryType, StatesType, TagObjectType } from '../../../interfaces';
import { makeStyles } from '@mui/styles';

export interface AuthorData {
	length: number;
	id: string;
	entityID: string;
	name: string;
	platformINcountryID: string;
	permalink: string;
	created: string;
}

interface Props {
	rowData: EntityRowData;
	isNewEntity: boolean;
	refreshTable: () => void;
	openEntityFromUrl?: boolean;
	onPointerEnterCapture?: (event: PointerEvent) => void;
	onPointerLeaveCapture?: (event: PointerEvent) => void;
}

const EntityFormModal: React.FC<Props> = ({
	isNewEntity,
	rowData,
	refreshTable,
	openEntityFromUrl,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, tagsActiveClient } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const formRef = useRef<FormHandles>(null);

	const [open, setOpen] = useState(false);
	const [openSecondDialog, setOpenSecondDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filesToUpload, setFilesToUpload] = useState<any>([]);
	const [selectedTags, setSelectedTags] = useState<TagObjectType[]>([]);
	const [selectedState, setSelectedState] = useState<StatesType>();
	const [selectedCountry, setSelectedCountry] = useState<CountryType>();
	const [registrationTypeID, setRegistrationTypeID] = useState<string>('');
	const [registrationNumberToProcess, setRegistrationNumberToProcess] =
		useState<string>('');

	const [name, setName] = useState(rowData?.name || '');
	const [email, setEmail] = useState(rowData?.email || '');
	const [phoneNumber, setPhoneNumber] = useState(rowData?.phoneNumber || '');
	const [registration, setRegistration] = useState(rowData?.registration || '');
	const [zipCode, setZipCode] = useState(rowData?.zipCode || '');
	const [address, setAddress] = useState(rowData?.address || '');
	const [addressNumber, setAddressNumber] = useState(
		rowData?.addressNumber || ''
	);
	const [cityName, setCityName] = useState(rowData?.cityName || '');
	const [observation, setObservation] = useState(rowData?.observation || '');
	const [authorsTableData, setAuthorsTableData] = useState<AuthorData[] | null>(
		null
	);
	const [singleAppraisalData, setSingleAppraisalData] = useState(null);

	const fetchAppraisalsData = async () => {
		setLoading(true);
		try {
			const response = await getAppraisals();
			if (response?.success) {
				const appraisal = response.data.find(
					(appraisal) => appraisal?.entityID === rowData?.id
				);
				setSingleAppraisalData(appraisal || null);
			} else {
				enqueueSnackbar(response?.message, { variant: 'error' });
			}
		} catch (error) {
			enqueueSnackbar('Failed to fetch appraisals data', { variant: 'error' });
		}
		setLoading(false);
	};

	useEffect(() => {
		if (openEntityFromUrl) {
			handleOpen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openEntityFromUrl]);

	useEffect(() => {
		if (open && authorsTableData === null) {
			fetchAppraisalsData();
			tableDataFetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorsTableData, open]);

	const useStyles = makeStyles(() => ({
		autoCompleteStyles: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const handleResetForm = () => {
		// eslint-disable-next-line no-unused-expressions
		setSelectedCountry(undefined);
		setSelectedState(undefined);
		setFilesToUpload([]);
		setSelectedTags([]);
		setAuthorsTableData(null);
		setRegistrationNumberToProcess('');
		setName('');
	};

	const handleClose = () => {
		handleResetForm();
		setOpen(false);
		setOpenSecondDialog(false);
	};

	const handleRefreshTable = () => {
		refreshTable();
	};

	const populateForm = () => {
		if (rowData.name) {
			if (rowData.tags) {
				const tagsToAdd = tagsActiveClient.filter((tag) =>
					rowData.tags.includes(tag.tagID)
				);
				setSelectedTags(tagsToAdd);
			}
			setRegistrationTypeID(
				rowData?.registrationTypeID && rowData?.registrationTypeID.toUpperCase()
			);
			setRegistrationNumberToProcess(rowData?.registration);
			setRegistration(rowData?.registration);
			setSelectedState(statesUf.find((el) => el.uf === rowData?.stateUf));
			setSelectedCountry(
				allCountries.find((el) => el.name === rowData?.countryName)
			);
		}
	};

	const dataToPopulate = {
		name: rowData?.name,
		email: rowData?.email,
		phoneNumber: rowData?.phoneNumber,
		registration: rowData?.registration,
		zipCode: rowData?.zipCode,
		address: rowData?.address,
		addressNumber: rowData?.addressNumber,
		cityName: rowData?.cityName,
		observation: rowData?.observation,
	};

	const handleOpen = () => {
		setOpen(true);
		populateForm();
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(3, t('Entidades.O nome precisa ter no mínimo 3 caracteres'))
			.required(t('Entidades.O nome é obrigatório')),
		email: Yup.string().email(t('Entidades.E-mail inválido')).notRequired(),
	});

	const handleSubmit = async () => {
		setLoading(true);
		const formData = {
			name: name,
			email: email,
			zipCode: zipCode,
			address: address,
			cityName: cityName,
			observation: observation,
			registrationTypeID: registrationTypeID,
			registration: registration,
			stateName: selectedState?.name,
			countryName: selectedCountry?.name,
			tags: selectedTags.map((el) => el.tagID),
			addressNumber: addressNumber === '' ? null : Number(addressNumber),
			phoneNumber:
				typeof phoneNumber === 'string'
					? phoneNumber.replace(/[^0-9]/g, '')
					: '',
		};
		try {
			await validationSchema.validate(formData, { abortEarly: false });
			formRef.current?.setErrors({});
			sendEntityData(formData);
			handleResetForm();
		} catch (err) {
			console.error('Erro de validação:', err);
			const validationErrors = {};

			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = !!error.message;
					enqueueSnackbar(error.message, { variant: 'error' });
				});
			}
			formRef.current?.setErrors(validationErrors);
			setLoading(false);
		}
	};

	const sendEntityData = async (payload) => {
		let response;
		if (!isNewEntity) {
			response = await updateEntity(
				payload,
				filesToUpload.filter((el) => el && el.file).map((el) => el.file),
				rowData.id
			);
			handleResetForm();
		}
		if (isNewEntity) {
			response = await createNewEntity(
				payload,
				filesToUpload.filter((el) => el && el.file).map((el) => el.file)
			);
			handleResetForm();
		}
		if (response.success) {
			response.message.forEach((el: string) => {
				if (el !== null) {
					enqueueSnackbar(
						t(
							el.split(' ')[0] === 'Arquivo'
								? el.split(' ').slice(0, -1).join(' ')
								: el
						),
						{
							variant: 'success',
						}
					);
				}
			});
			setLoading(false);
			handleClose();
			handleRefreshTable();
		} else {
			enqueueSnackbar(t(response.message[0]), {
				variant: 'error',
			});
			setLoading(false);
		}
	};

	const handleRemoveFileFromServer = async (file: any) => {
		const removeFileResponse: any = await removeEntitiesFiles(
			rowData.id,
			file.url
		);
		if (removeFileResponse.success === true) {
			enqueueSnackbar(t('FileCard.Arquivo removido com sucesso'), {
				variant: 'success',
			});
			return true;
		} else {
			enqueueSnackbar(t('FileCard.Erro ao remover arquivo'), {
				variant: 'error',
			});
			return false;
		}
	};

	const handlePostalCodeChange = async (event: any) => {
		const postalCode = event.target.value.replace(/[^0-9]/g, '');
		if (postalCode.length === 8) {
			try {
				const address = await cep(postalCode);
				if (address.state) {
					setSelectedState(statesUf.find((el) => el.uf === address.state));
					setAddress(address.street);
					setCityName(address.city);
					setZipCode(address.cep);
					setSelectedCountry({ name: 'Brasil' });
				}
			} catch (error) {
				enqueueSnackbar(t('Entidades.CEP não encontrado'), {
					variant: 'warning',
				});
			}
		}
	};

	const checkRegistrationNumberType = (event) => {
		if (event.target.value.length === 14) {
			setRegistrationTypeID('CPF');
		}
		if (event.target.value.length === 18) {
			setRegistrationTypeID('CNPJ');
		}
		return '';
	};

	const OpenExternalLink = (props) => {
		return (
			<OfferToolTip
				title={t('Entidades.Ver perfil na plataforma')}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					size="small"
					href={props.rowData?.permalink}
					target="_blank"
					style={{ marginBottom: -1 }}
					data-testid="openExternalLinkButton"
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Link
						style={{
							fontSize: 18,
							transform: 'rotate(-45deg)',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</IconButton>
			</OfferToolTip>
		);
	};

	const profilesColsData = [
		{
			columnTitle: t('Id'),
			fieldName: 'id',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Entidades.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 20px',
			cellWidth: 250,
		},
		{
			columnTitle: t('Entidades.Plataforma'),
			fieldName: 'platformINcountryID',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 20px',
			cellWidth: 150,
			dataType: 'platformINcountry',
		},
		{
			columnTitle: t('Entidades.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 20px',
			cellWidth: 200,
			dataType: 'date',
		},
	];

	const profilesTableActions = [
		{
			component: OpenAuthorCard,
			props: {},
		},
		{
			component: OpenExternalLink,
			props: {},
		},
	];

	const reportModalTableActions = [
		{
			component: OpenExternalLink,
			props: {},
		},
	];

	const tableDataFetch = async () => {
		if (!isNewEntity) {
			const expandedResponse = await getAuthorsOnEntity(rowData.id);
			if (
				expandedResponse &&
				expandedResponse.success &&
				expandedResponse.data.length > 0
			) {
				const tempTableData: any = [];
				expandedResponse.data.forEach((el: any) => {
					const formatedAuthor = {
						id: el.id,
						entityID: rowData.id,
						name: el.name,
						platformINcountryID: el.platformINcountryID,
						permalink: el.permalink,
						created: el.created,
					};
					tempTableData.push(formatedAuthor);
				});
				setAuthorsTableData(tempTableData);
			} else if (
				expandedResponse &&
				expandedResponse.success &&
				expandedResponse.data.length === 0
			) {
				setAuthorsTableData([]);
			}
			setLoading(false);
		}
	};

	const renderTitle = () => {
		if (!isNewEntity) {
			return `${t('Entidades.Editar entidade')}: ${name}`;
		} else if (openEntityFromUrl) {
			return rowData.name;
		}
		return t('Entidades.Adicionar entidade');
	};

	const renderHeader = () => (
		<>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						noWrap
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{renderTitle()}
					</Typography>
					<IconButton
						data-testid="closedButton"
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		</>
	);

	const renderProfilesTable = () =>
		authorsTableData && authorsTableData.length > 0 ? (
			<OfferTable
				tableWidth="700px"
				denseText
				tableData={authorsTableData}
				colsData={profilesColsData}
				loading={loading}
				tableActions={profilesTableActions}
				CustomHeaderComponent={EntityFormModal}
				noShadow
				customNoDataMessage={t('Entidades.Nenhum autor vinculado')}
			/>
		) : (
			<Line
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					height: 40,
					width: '100%',
					border: `1px solid ${selectedTheme.borderLight}`,
					borderRadius: 4,
				}}
			>
				<Typography
					variant="subtitle2"
					style={{
						opacity: 0.6,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Entidades.Nenhum autor vinculado')}
				</Typography>
			</Line>
		);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				width: 750,
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<Column
				style={{
					rowGap: 15,
					marginBottom: 10,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<TextField
					name="name"
					label={t('Entidades.Nome')}
					data-testid="name"
					variant="outlined"
					size="small"
					value={name}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setName(event.target.value);
					}}
					fullWidth
					sx={{
						width: '100%',
						'& .MuiFormLabel-root': {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						},
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						readOnly: !isNewEntity,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
				/>
				<Line style={{ justifyContent: 'space-between', columnGap: 15 }}>
					<TextField
						name="registration"
						label={t('Entidades.Número de registro')}
						data-testid="registration"
						variant="outlined"
						size="small"
						value={registration}
						onChange={(event) => {
							checkRegistrationNumberType(event);
							setRegistrationNumberToProcess(event.target.value);
							setRegistration(event.target.value);
						}}
						sx={{
							width: '100%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						fullWidth
						InputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
							inputComponent: TextMaskDynamicRegNumber as any,
							endAdornment: (
								<Typography
									variant="caption"
									style={{
										color:
											cnpj.isValid(registrationNumberToProcess) ||
											cpf.isValid(registrationNumberToProcess)
												? selectedTheme.success
												: selectedTheme.error,
										marginBottom: -2,
									}}
								>
									{registrationTypeID?.toUpperCase() || ''}
								</Typography>
							),
						}}
					/>
					<TextField
						name="email"
						label={t('Entidades.E-mail')}
						data-testid="email"
						variant="outlined"
						size="small"
						fullWidth
						value={email}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setEmail(event.target.value);
						}}
						sx={{
							width: '100%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
					<TextField
						name="phoneNumber"
						label={t('Entidades.Telefone')}
						data-testid="phone"
						variant="outlined"
						size="small"
						fullWidth
						value={phoneNumber}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setPhoneNumber(event.target.value);
						}}
						sx={{
							width: '100%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						InputProps={{
							inputComponent: TextMaskPhone as any,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
				</Line>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Localização')}
					margin="5px"
				/>
				<Line style={{ justifyContent: 'space-between', columnGap: 15 }}>
					<TextField
						name="zipCode"
						label={t('Entidades.CEP')}
						data-testid="zipCode"
						variant="outlined"
						size="small"
						value={zipCode}
						onChange={handlePostalCodeChange}
						InputProps={{
							inputComponent: TextMaskCEP as any,
						}}
						fullWidth
						sx={{
							width: '20%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
					<TextField
						name="address"
						label={t('Entidades.Logradouro')}
						data-testid="address"
						variant="outlined"
						size="small"
						fullWidth
						value={address}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setAddress(event.target.value);
						}}
						sx={{
							width: '60%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
					<TextField
						name="addressNumber"
						label={t('Entidades.Número')}
						data-testid="addressNumber"
						variant="outlined"
						size="small"
						fullWidth
						value={addressNumber}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setAddressNumber(event.target.value);
						}}
						sx={{
							width: '20%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
				</Line>
				<Line style={{ justifyContent: 'space-between', columnGap: 15 }}>
					<TextField
						name="cityName"
						label={t('Entidades.Cidade')}
						data-testid="cityName"
						variant="outlined"
						size="small"
						fullWidth
						value={cityName}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setCityName(event.target.value);
						}}
						sx={{
							width: '40%',
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
					/>
					<Autocomplete
						data-testid="stateName"
						id="state-select"
						style={{
							width: '30%',
							cursor: 'pointer',
						}}
						options={statesUf}
						value={selectedState}
						onChange={(event, newValue) => {
							setSelectedState(newValue);
						}}
						classes={{
							option: classes.autoCompleteStyles,
							root: classes.autoCompleteStyles,
							paper: classes.autoCompleteStyles,
						}}
						autoHighlight
						autoComplete
						autoSelect
						disableClearable
						loadingText={t('Carregando...')}
						selectOnFocus
						getOptionLabel={(option) => option.name.toString()}
						renderOption={(props, option: StatesType) => (
							<li {...props}>{option.name}</li>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								{...params}
								variant="outlined"
								name="stateName"
								placeholder={t('Entidades.Estado')}
								data-testid="stateTextField"
								size="small"
								inputProps={{
									...params.inputProps,
									style: {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
					<Autocomplete
						data-testid="countryName"
						id="country-select"
						style={{
							width: '30%',
							cursor: 'pointer',
						}}
						options={allCountries}
						value={selectedCountry}
						onChange={(event, newValue) => {
							setSelectedCountry(newValue);
						}}
						autoHighlight
						autoComplete
						autoSelect
						classes={{
							option: classes.autoCompleteStyles,
							root: classes.autoCompleteStyles,
							paper: classes.autoCompleteStyles,
						}}
						disableClearable
						loadingText={t('Carregando...')}
						selectOnFocus
						getOptionLabel={(option) => option.name || ''}
						renderOption={(props, option: CountryType) => (
							<li {...props}>{option.name}</li>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								{...params}
								variant="outlined"
								name="countryName"
								placeholder={t('Entidades.País')}
								data-testid="countryTextField"
								size="small"
								inputProps={{
									...params.inputProps,
									style: {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
				</Line>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Anexos')}
					margin="5px"
				/>
				<MultiFileCard
					setUploadedFiles={setFilesToUpload}
					useTextField
					maxFiles={4}
					preloadUrlFiles={rowData?.attachments}
					handleRemoveFileFromServer={handleRemoveFileFromServer}
				/>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name={t('Entidades.Etiquetas')}
					margin="-8px 5px 5px 5px"
				/>
				<TagSelector
					dataArray={selectedTags}
					setDataArray={setSelectedTags}
					suggestions={tagsActiveClient}
					size="small"
					tagSize="small"
				/>
				<FormDivider
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
					name=""
					margin="5px"
				/>
				<TextField
					name="observation"
					label={t('Entidades.Observações')}
					data-testid="observation"
					variant="outlined"
					size="small"
					fullWidth
					multiline
					value={observation}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setObservation(event.target.value);
					}}
					rows={2}
					sx={{
						'& .MuiFormLabel-root': {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						},
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
				/>
				{!isNewEntity && (
					<>
						<FormDivider
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.overlay6dp
									: selectedTheme.foreground
							}
							name={t('Entidades.Laudo')}
							margin="5px"
						/>
						{!loading ? (
							<Line
								style={{
									height: 40,
									width: '100%',
									borderRadius: 4,
								}}
							>
								<IconButton
									data-testid="newAppraisal"
									style={{
										padding: 5,
										borderRadius: 4,
										width: '100%',
									}}
									sx={{
										border: `1px solid ${selectedTheme.borderLight}`,
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
									onClick={() => {
										setOpenSecondDialog(true);
									}}
								>
									<Typography
										variant="subtitle1"
										style={{
											fontWeight: 'bold',
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primary,
											marginRight: 15,
											marginTop: 2,
										}}
									>
										{singleAppraisalData
											? t('Entidades.Editar Laudo')
											: t('Entidades.Adicionar Laudo')}
									</Typography>
									{singleAppraisalData ? (
										<Edit
											style={{
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: selectedTheme.primary,
											}}
										/>
									) : (
										<AddCircle
											style={{
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: selectedTheme.primary,
											}}
										/>
									)}
								</IconButton>
							</Line>
						) : null}
					</>
				)}

				{!isNewEntity && (
					<>
						<FormDivider
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.overlay6dp
									: selectedTheme.foreground
							}
							name={t('Entidades.Autores Vinculados')}
							margin="5px"
						/>
						{loading ? (
							<Line
								style={{
									justifyContent: 'center',
									alignItems: 'center',
									height: 40,
									width: '100%',
									border: `1px solid ${selectedTheme.borderLight}`,
									borderRadius: 4,
								}}
							>
								<CircularProgress size={15} />
							</Line>
						) : (
							renderProfilesTable()
						)}
					</>
				)}
			</Column>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			{openEntityFromUrl ? (
				<Line style={{ justifyContent: 'flex-end' }}>
					<Button
						size="small"
						onClick={handleClose}
						data-testid="closedButton"
						disabled={loading}
						style={{ color: loading ? 'gray' : selectedTheme.primaryDark }}
					>
						{t('Entidades.Fechar')}
					</Button>
				</Line>
			) : (
				<Line style={{ justifyContent: 'space-between' }}>
					<Button
						size="small"
						onClick={handleClose}
						disabled={loading}
						data-testid="cancelButton"
						style={{ color: loading ? 'gray' : selectedTheme.error }}
					>
						{t('Entidades.Cancelar')}
					</Button>

					<Button
						type="submit"
						data-testid="submitButton"
						disabled={loading}
						style={{
							width: 90,
							height: 30,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							background:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: selectedTheme.foreground
							}`,
						}}
					>
						{loading ? (
							<CircularProgress
								size={15}
								style={{ color: selectedTheme.foreground }}
							/>
						) : (
							t('Entidades.Salvar')
						)}
					</Button>
				</Line>
			)}
		</DialogActions>
	);

	const renderEditIcon = () => (
		<OfferToolTip
			title={t('Entidades.Editar entidade')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				data-testid="editEntityButton"
				onClick={handleOpen}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<EditRounded
					style={{
						fontSize: 20,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderAddIcon = () => (
		<OfferToolTip
			title={t('Entidades.Adicionar entidade')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				data-testid="newEntityButton"
				style={{ marginRight: '-15px', borderRadius: 10 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				onClick={() => setOpen(true)}
			>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 'bold',
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						marginRight: 15,
						marginTop: 2,
					}}
				>
					{t('Entidades.Adicionar entidade')}
				</Typography>
				<AddCircle
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderOpenIcon = () => {
		if (openEntityFromUrl) {
			return;
		} else {
			if (!isNewEntity) {
				return renderEditIcon();
			}
			return renderAddIcon();
		}
	};

	return (
		<>
			{renderOpenIcon()}
			<Dialog
				open={open}
				onClose={handleClose}
				data-testid="modalOpen"
				aria-label="confirm-edit-dialog"
				scroll="body"
				maxWidth="md"
			>
				{renderHeader()}
				<Form
					onPointerEnterCapture={undefined}
					onPointerLeaveCapture={undefined}
					placeholder=""
					onSubmit={handleSubmit}
					ref={formRef}
					initialData={dataToPopulate}
				>
					{renderDialogContent()}
					{renderFooter()}
				</Form>
				<AppraisalModal
					open={openSecondDialog}
					setOpen={setOpenSecondDialog}
					rowData={rowData}
					tableData={authorsTableData}
					colsData={profilesColsData}
					tableActions={reportModalTableActions}
					singleAppraisalData={singleAppraisalData}
					handleParentClose={handleClose}
				/>
			</Dialog>
		</>
	);
};
export default EntityFormModal;
